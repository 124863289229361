<template>
  <div class="container-fluid w-100">
    <b-card class="d-flex flex-row justify-content-center w-100">
      <b-row class="justify-content-center mt-1">
        <!-- h-64 -->
        <img
          src="@/assets/images/people.svg"
          alt="content-img"
          class=""
        >
      </b-row >
      <b-row class="justify-content-center mt-1">
        <p>
          Refer someone and receive 20% commission of their first year
          subscription (T&C Applies)<br><br>
          Click below to copy your referral code
        </p>
      </b-row>
      <b-row class="justify-content-center mt-1 mb-2">
        <b-form-input
          v-model="referral_code"
          size="lg"
          style="width: 25rem;"
          class="sm-w-50 font-bold text-2xl justify-content-center"
          @click="onCopy(referral_code)"
        />
      </b-row>
    </b-card>

    <b-row class="">
      <b-modal
        v-model="popupActive"
        centered
        hide-footer
        title="Terms and conditions"
      >
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
      </b-modal>
    </b-row>
    <div class="vx-row justify-center mt-5">
      <splide
        v-if="show_slide"
        class="w-full"
        :options="splide_options"
      >
        <splide-slide>
          <div class="vx-col w-full mb-base">
            <b-card class="overlay-card overflow-hidden">
              <div slot="no-body">
                <img
                  src="https://i.ibb.co/R0F3yv0/coming-soon.jpg"
                  alt="content-img"
                  class="responsive card-img-top"
                >
              </div>
              <hr/>
              <div class="flex justify-between">
                <h4 class="text-bold mt-2">
                  PIZZA INN
                </h4>
                <b-form-input
                  v-model="referral_code"
                  class="md:w-1/2 font-bold text-2xl"
                />
              </div>
              <hr/>
              <div class="vx-row justify-center mt-4">
                <b-button
                  @click="popupActive = true"
                >Terms and conditions</b-button>
              </div>
            </b-card>
          </div>
        </splide-slide>
        <splide-slide>
          <div class="vx-col w-full mb-base">
            <b-card class="overlay-card overflow-hidden">
              <div slot="no-body">
                <img
                  src="https://i.ibb.co/R0F3yv0/coming-soon.jpg"
                  alt="content-img"
                  class="responsive card-img-top"
                >
              </div>
              <hr/>
              <div class="flex justify-between">
                <h4 class="text-bold mt-2">
                  SUSHI PLACE
                </h4>
                <b-form-input
                  v-model="referral_code"
                  class="md:w-1/2 font-bold text-2xl"
                />
              </div>
              <hr/>
              <div class="vx-row justify-center mt-4">
                <b-button>Terms and conditions</b-button>
              </div>
            </b-card>
          </div>
        </splide-slide>
        <splide-slide>
          <div class="vx-col w-full mb-base">
            <b-card class="overlay-card overflow-hidden">
              <div slot="no-body">
                <img
                  src="https://i.imgur.com/CJ28yzb.png"
                  alt="content-img"
                  class="responsive card-img-top"
                >
              </div>
              <hr/>
              <div class="flex justify-between">
                <h4 class="text-bold mt-2">
                  KFC PLACE
                </h4>
                <b-form-input
                  v-model="referral_code"
                  class="md:w-1/2 font-bold text-2xl"
                />
              </div>
              <hr/>
              <div class="vx-row justify-center mt-4">
                <b-button>Terms and conditions</b-button>
              </div>
            </b-card>
          </div>
        </splide-slide>
      </splide>
    </div>
  </div>
</template>
<script>
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BFormInput,
  BButton,
  BRow,
} from 'bootstrap-vue'

export default {
  components: {
    Splide,
    SplideSlide,
    BCard,
    BFormInput,
    BButton,
    BRow,
  },
  data() {
    return {
      show_slide: false,
      video_active: true,
      referral_code: '',
      popupActive: false,
      splide_options: {
        rewind: true,
        width: 1500,
        perPage: 3,
        gap: '2rem',
      },
    }
  },
  computed: {},
  created() {
    this.generateCode()
  },
  methods: {
    generateCode() {
      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.$http
        .get('/api/refferal_code')
        .then(response => {
          const { code } = response.data
          this.referral_code = code
        })
        .catch(error => {})
    },

    onCopy(text) {
      this.copyTextToClipboard(text)
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Verifcation Update',
            icon: 'CheckCircleIcon',
            text: 'Referral code copied successfully',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        err => {},
      )
    },
  },
}
</script>

<style lang="scss"></style>
